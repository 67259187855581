<template>
  <div>
    <div class="alert alert-primary alert-border-left alert-dismissible fade show" role="alert">
      <strong>
        Справочно: Номер дела - {{ courtDecisionDocNo }}. Суммы по решению - Долг: {{ debts }}, Гос.пошлина: {{ stateFee }}, Пени:
        {{ penalties }}
      </strong>
    </div>
    <div class="row">
      <div class="col">
        <DxDataGrid
          id="gvPayments"
          ref="gvPayments"
          v-bind="options"
          dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
          :data-source="paymentsStore"
          @init-new-row="onNewRow"
        >
          <DxColumn data-field="date" caption="Дата" data-type="date">
            <DxRequiredRule />
          </DxColumn>
          <DxColumn data-field="sumByDebts" caption="За долг" edit-cell-template="sumTemplate">
            <DxRequiredRule />
            <DxFormat type="fixedPoint" :precision="2" />
          </DxColumn>
          <DxColumn data-field="sumByPenalties" caption="За пени" edit-cell-template="sumTemplate">
            <DxRequiredRule />
            <DxFormat type="fixedPoint" :precision="2" />
          </DxColumn>
          <DxColumn data-field="sumByStateFee" caption="За гос.пошлину" edit-cell-template="sumTemplate">
            <DxRequiredRule />
            <DxFormat type="fixedPoint" :precision="2" />
          </DxColumn>
          <DxColumn data-field="paymentByWritSourceId" caption="От кого">
            <DxRequiredRule />
            <DxLookup :data-source="paymentByWritSourcesStore" display-expr="name" value-expr="id" />
          </DxColumn>
          <DxColumn data-field="comment" caption="Комментарий">
            <DxFormItem :col-span="2" />
          </DxColumn>

          <template #sumTemplate="{ data }">
            <DxNumberBox
              class="w-100 me-2"
              :show-clear-button="true"
              :value="data.value"
              format="#,##0.00"
              @value-changed="(e) => data.setValue(e.value)"
            />
          </template>

          <DxEditing :allow-adding="true" :allow-updating="true" :allow-deleting="true" mode="form">
            <DxForm>
              <DxItem data-field="date" />
              <DxItem data-field="comment" />
              <DxItem data-field="sumByDebts" />
              <DxItem data-field="sumByPenalties" />
              <DxItem data-field="sumByStateFee" />
              <DxItem data-field="paymentByWritSourceId" />
            </DxForm>
          </DxEditing>
          <DxSummary>
            <DxTotalItem column="sumByDebts" summary-type="sum" valueFormat="#,##0.##" display-format="Сумма: {0}" />
            <DxTotalItem column="sumByPenalties" summary-type="sum" valueFormat="#,##0.##" display-format="Сумма: {0}" />
            <DxTotalItem column="sumByStateFee" summary-type="sum" valueFormat="#,##0.##" display-format="Сумма: {0}" />
          </DxSummary>
          <DxFilterRow :visible="true" />
          <DxScrolling row-rendering-mode="virtual" />
          <DxPaging :page-size="20" />
          <DxPager
            :visible="true"
            :allowed-page-sizes="[5, 10, 20, 50]"
            :show-page-size-selector="true"
            :show-navigation-buttons="true"
            :show-info="true"
          />
        </DxDataGrid>
      </div>
    </div>
  </div>
</template>

<script>
import { DxForm, DxFormItem, DxSummary, DxTotalItem } from "devextreme-vue/data-grid";
import { DxItem } from "devextreme-vue/form";
import { useWritCardStore } from "../../state/writ.card";
import { storeToRefs } from "pinia";

import createStoreExtend from "@/helpers/grid/store";
import settings from "@/helpers/grid/settings.js";

export default {
  components: {
    DxSummary,
    DxTotalItem,
    DxFormItem,
    DxForm,
    DxItem,
  },
  setup() {
    const store = useWritCardStore();
    const { writ } = storeToRefs(store);
    return { store, writ };
  },
  mounted() {},
  computed: {
    writId() {
      return this.writ.id;
    },
    courtDecisionDocNo() {
      return this.writ?.courtDecisionExecutionDocNo ?? "";
    },
    debts() {
      return this.writ?.courtDecisionSum ?? "";
    },
    stateFee() {
      return this.writ?.courtDecisionStateFee ?? "";
    },
    penalties() {
      return this.writ?.courtDecisionPenalties ?? "";
    },
  },
  data() {
    return {
      options: settings,

      paymentByWritSourcesStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/common/payment-by-writ-sources`,
      }),
      paymentsStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_PAYMENTS_BY_WRITS_GET}`,
        insertUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_PAYMENTS_BY_WRITS_CREATE}`,
        updateUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_PAYMENTS_BY_WRITS_UPDATE}`,
        deleteUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_PAYMENTS_BY_WRITS_DELETE}`,
        onBeforeSend: (_method, ajaxOptions) => {
          ajaxOptions.data.writId = this.writId;
        },
        onLoaded: (result) => {
          this.store.setPaymentsCount(result.length);
        },
      }),
    };
  },
  methods: {
    onNewRow(e) {
      e.data.paymentByWritSourceId = 2;
    },
  },
};
</script>

<style></style>
